import $ from 'jquery-slim';

class Option
{
    constructor($element, map, select, mark)
    {
        this.$element = $element;

        this.$title = this.$element.find('h5');
        this.$title.on('click', select);

        this.position = {lat: parseFloat(this.$element.attr('latitude')), lng: parseFloat(this.$element.attr('longitude'))};

        this.marker = new google.maps.Marker({position:this.position, map:map});
        google.maps.event.addListener(this.marker, 'click', select);

        this.place();

    }

    select()
    {
        this.$element[0].setAttribute('selected', '');
    }

    place()
    {
        let icon =
        {
            url: '/webcare-theme/images/charte2/delivery/map_marker' + (this.$element[0].hasAttribute('selected') ? '_selected' : '' ) + '.png',
            size: new google.maps.Size(96, 128),
            scaledSize: new google.maps.Size(24, 32),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(12, 25)
        };

        this.marker.setIcon(icon);
    }

    clear()
    {
        this.$button.off('click');
        this.marker.setMap(null);
    }

    extend(bounds)
    {
        bounds.extend(this.position);
    }

}

class MapList
{
    constructor($element)
    {
        this.$element = $element;
        this.$map = this.$element.find('.opt-map');
        this.$locations = this.$element.find('.opt-locations');
        this.scrollbar = this.$element.find('[opt-scrollbar]').overlayScrollbars();

        let observerOptions = {
            childList: true,
            attributes: false,
            subtree: false
        }

        this.observer = new MutationObserver(this.change.bind(this));
        this.observer.observe(this.$locations[0], observerOptions);
    }

    
    
    create()
    {
        
        let mapOptions =
            {
                mapTypeId: 'roadmap',
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                zoomControl: true,
                zoomControlOptions:
                    {
                        position: google.maps.ControlPosition.RIGHT_BOTTOM
                    }
            };
        this.map = new google.maps.Map(this.$map[0], mapOptions);

        this.populate();

        google.maps.event.addListenerOnce(this.map, 'tilesloaded', () =>
        {
            this.map.fitBounds(this.bounds);
        });
    }

    change()
    {
        for (let i = 0; i < this.options.length; i++) this.options[i].clear();
        this.populate();
    }

    populate()
    {
        this.bounds = new google.maps.LatLngBounds();

        let $locations = this.$locations.find('.opt-location');

        this.options = [];

        let option;

        for (let i = 0; i < $locations.length; i++)
        {

            option = new Option($locations.eq(i), this.map, this.select.bind(this, i, false));

            option.extend(this.bounds);

            this.options[i] = option;
        }

        this.map.fitBounds(this.bounds);

        if (this.options.length < 2) this.map.setZoom(14);




    }

    select(index, isMarker)
    {
        this.$element.find('.opt-location').attr('selected', null);

        this.options[index].select();

        this.scrollbar.scroll({ el: this.options[index].$element , block : "begin"}, 500);

        for (let i = 0; i < this.options.length; i++) this.options[i].place();
    }
}

class OptMapList
{
    constructor()
    {

        window.initMap = this.create.bind(this);

        $(document).ready(this.init.bind(this));
    }

    init()
    {
        let $mapLists = $('.opt-map-list');

        this.mapLists = [];

        for (let i = 0; i < $mapLists.length; i++) this.mapLists.push(new MapList($mapLists.eq(i)));

        if (this.isCreated) this.create();

    }

    create()
    {
        this.isCreated = true;
        if (this.mapLists !== undefined) for (let i = 0; i < this.mapLists.length; i++) this.mapLists[i].create();
    }



}

const mapList = new OptMapList();






